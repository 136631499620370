<template>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
button {
  color: black;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.button,
button {
  display: inline-block;
  padding: 5px 5px;
  box-sizing: border-box;
}
.button.router-link-exact-active {
  color: #42b983;
}

.container {
  max-width: 320px;
  margin: 0px auto;
}
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.header {
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
}
.monthLbl.button {
  padding: 10px 5px;
}
.navigate {
  align-items: center;
  display: flex;
}
.navigate .button {
  margin: 0px 10px;
  padding: 5px 15px;
}

</style>
