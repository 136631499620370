import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: `/year/${new Date().getFullYear()}/month/${new Date().getMonth()}`,
  },
  {
    path: '/years/:years',
    name: 'Years',
    // route level code-splitting
    // this generates a separate chunk (years.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "years" */ '../views/Years.vue')
  },
  {
    path: '/year/:year',
    name: 'Year',
    // route level code-splitting
    // this generates a separate chunk (year.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "year" */ '../views/Year.vue')
  },
  {
    path: '/year/:year/month/:month',
    name: 'Month',
    // route level code-splitting
    // this generates a separate chunk (year-w-month.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "year-w-month" */ '../views/Month.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
